<template>
    <div v-loading="loading">

        <el-collapse ref="collapse" value="collapseItem" style="margin-bottom: 10px" @change="heightChange">
            <el-collapse-item name="collapseItem" class="form-container">
                <template slot="title">
                    <el-tag type="success" style="width: 100%;padding-left: 15px">
                        <i class="el-icon-search" style="margin-right: 5px"></i>
                        <span>共查询到 {{ planList.length }} 条记录</span>
                    </el-tag>
                </template>

                <el-form :inline="true"
                         :model="searchObj"
                         class="form"
                         size="small"
                         label-width="72px">

                    <el-form-item>
                        <el-radio-group v-model="searchObj.type" size="small" @change="submit">
                            <el-radio-button label="催费中"></el-radio-button>
                            <el-radio-button label="已完成"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="所属项目:" style="margin-left: 10px">
                        <el-select v-model="searchObj.projName" multiple collapse-tags
                                   placeholder="所属项目" style="width: 100%" @change="submit">
                            <el-option v-for="project in projects" :key="project.abbreviation"
                                       :value="project.projName"
                                       :label="project.projName"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="客户名称:">
                        <el-input v-model="searchObj.clientName" placeholder="客户名称" clearable
                                  @change="submit"></el-input>
                    </el-form-item>

                    <el-form-item style="float: right;margin-right: 20px">
                        <el-button type="danger" @click="createDrawer = true">创建催费 <i class="el-icon-folder-opened"></i>
                        </el-button>
                    </el-form-item>
                </el-form>

            </el-collapse-item>

        </el-collapse>

        <div :style="tableContainerStyle" v-infinite-scroll="load">

<!--            <ul class="my-list">-->
<!--                <li v-for="plan in planList" :key="plan.id">-->
<!--                    <el-card shadow="always">-->
<!--                        <div slot="header" class="clearfix">-->
<!--                            <span>{{plan.id + plan.clientName}}</span>-->
<!--                            <el-button style="float: right; padding: 3px 0" type="text">查看</el-button>-->
<!--                        </div>-->
<!--                        <el-descriptions :column="1">-->
<!--                            <el-descriptions-item label="欠费项目">{{plan.billFee}}</el-descriptions-item>-->
<!--                            <el-descriptions-item label="欠费金额">{{FormartMoney(plan.billAmount)}}</el-descriptions-item>-->
<!--                            <el-descriptions-item label="相关房源">-->
<!--                                <el-tag size="small">{{plan.billHouse === ''?'无':plan.billHouse}}</el-tag>-->
<!--                            </el-descriptions-item>-->
<!--                            <el-descriptions-item label="处理人">{{plan.makerName + ',' + plan.coordinatorName}}</el-descriptions-item>-->
<!--                            <el-descriptions-item label="最新跟进">{{plan.changeTime}}</el-descriptions-item>-->
<!--                        </el-descriptions>-->
<!--                    </el-card>-->
<!--                </li>-->

<!--                &lt;!&ndash; 更多列表项 &ndash;&gt;-->
<!--            </ul>-->

            <el-row :gutter="20" >
                <el-col :span="8" v-for="plan in planList" :key="plan.id" style="margin-bottom: 10px">
                    <el-card shadow="always" >
                        <div slot="header" class="clearfix">
                            <span>{{plan.id + plan.clientName}}</span>
                            <el-button style="float: right; padding: 3px 0" type="text">查看</el-button>
                        </div>
                        <el-descriptions :column="1">
                            <el-descriptions-item label="欠费项目">{{plan.billFee}}</el-descriptions-item>
                            <el-descriptions-item label="欠费金额">{{FormartMoney(plan.billAmount)}}</el-descriptions-item>
                            <el-descriptions-item label="相关房源">
                                <el-tag size="small">{{plan.billHouse === ''?'无':plan.billHouse}}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item label="处理人">{{plan.makerName + ',' + plan.coordinatorName}}</el-descriptions-item>
                            <el-descriptions-item label="最新跟进">{{plan.changeTime}}</el-descriptions-item>
                        </el-descriptions>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 创建催收 -->
        <el-drawer :visible.sync="createDrawer" size="70%" destroy-on-close
                   :close-on-press-escape="false"
                   :wrapperClosable="false">
            <template slot="title">
                <el-alert
                        title="创建催费计划"
                        type="error"
                        :closable="false">
                </el-alert>
            </template>

            <WuyeCollectionCreate @WuyeCollectionCreateClose="WuyeCollectionCreateClose"></WuyeCollectionCreate>
        </el-drawer>

    </div>
</template>

<script>
    import WuyeCollectionCreate from "./WuyeCollectionCreate";
    import {GetProjectsWithoutAll, GetMyWuyeCollectionList, GetWuyeEmployeeList} from "../../api";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeCollectionManagement",
        components: {WuyeCollectionCreate},
        data() {
            return {
                unionid: null,
                loading: false,
                bigHeight: null,
                tableContainerStyle: {
                    height: '',
                    width: '100%',
                    overflow: 'auto'
                },
                searchObj: {
                    type: '催费中',
                    projName: [],
                    clientName: ''
                },
                createDrawer: false,
                projects: [],
                pageSize: 10,
                currentPage: 1,
                nowCounts: 0,
                planList: []
            }
        },
        methods: {
            FormartMoney(value) {
                return formatMoney(value)
            },
            heightChange(val) {
                if (val.length > 0) {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
                } else {
                    this.tableContainerStyle.height = '-webkit-calc(100vh - 225px)'
                }
            },
            GetPlanList() {
                this.loading = true
                GetMyWuyeCollectionList(this.pageSize,this.currentPage,this.unionid,'1',this.searchObj).then((res) => {
                    this.nowCounts = res.list.length
                    this.planList = [...this.planList, ...res.list]
                    this.loading = false
                })
            },
            submit() {
                this.planList = []
                this.currentPage = 1
                this.nowCounts = 0
                this.GetPlanList()
            },
            WuyeCollectionCreateClose() {
                this.createDrawer = false
                this.submit()
            },
            load() {
                if (this.nowCounts < this.pageSize) {

                } else {
                    this.currentPage++;
                    this.GetPlanList();
                }
            }
        },
        async created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.loading = true

            await GetProjectsWithoutAll().then((res) => {
                this.searchObj.projName = res.map(item => item.projName)
                this.projects = res
            })

            await this.submit()

            this.loading = false
        },
        mounted() {
            this.$nextTick(function () {
                this.bigHeight = this.$refs.collapse.$el.offsetHeight
                this.tableContainerStyle.height = '-webkit-calc(100vh - ' + (this.bigHeight + 170) + 'px)'
            })
        }
    }
</script>

<style scoped>

    /*修改折叠面板的padding*/
    .form-container >>> .el-collapse-item__content {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    /*表单 select选择器 样式*/
    .el-select {
        width: 200px
    }

    .my-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0px;
        background-color: red;
    }
    .my-list li {
        width: 25%;
    }

</style>
