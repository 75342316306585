<template>
    <div class="container" v-loading="loading">

        <el-form ref="wuyeCollectionForm" :model="form" :rules="rules" label-width="80px" size="medium">

            <el-form-item label="所属项目" prop="projName">
                <el-select v-model="form.projName" placeholder="所属项目" style="width: 100%" @change="ProjChange">
                    <el-option v-for="project in projects" :key="project.abbreviation"
                               :value="project.projName"
                               :label="project.projName"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="选择客户" prop="clientId">
                <el-select v-model="form.clientId" filterable @change="ClientChange"
                           placeholder="请选择客户" style="width: 100%" :disabled="!(clients.length > 0)">
                    <el-option
                            v-for="item in clients"
                            :key="item.clientId"
                            :label="item.clientName"
                            :value="item.clientId">
                        <span style="float: left">{{ item.clientName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.clientId }}</span>
                    </el-option>
                </el-select>
            </el-form-item>

            <vxe-grid ref="xTable"
                      id="collectionedBillTable"
                      class="collectionedBillTable"
                      v-bind="collectionedBillTable"
                      @checkbox-change="CheckboxChange"
                      @checkbox-all="CheckboxChange">
            </vxe-grid>

            <el-form-item label="总金额">
                <el-input :value="FormartMoney(form.amount)" disabled></el-input>
            </el-form-item>

            <el-form-item label="协同人" prop="coordinator">
                <el-select v-model="form.coordinator" placeholder="协同人" style="width: 100%"
                           clearable multiple filterable>
                    <el-option v-for="employee in employeeList" :key="employee.unionid"
                               :value="employee.unionid"
                               :label="employee.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="督办人" prop="recipient">
                <el-select v-model="form.recipient" placeholder="督办人" style="width: 100%"
                           clearable multiple filterable>
                    <el-option v-for="employee in employeeList" :key="employee.unionid"
                               :value="employee.unionid"
                               :label="employee.name"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="情况描述" prop="remark">
                <el-input
                        type="textarea"
                        :autosize="{ minRows: 4}"
                        placeholder="情况描述"
                        v-model="form.remark">
                </el-input>
            </el-form-item>


            <el-form-item style="text-align: right">
                <el-button size="small" type="warning" @click="Submit">发 起</el-button>
            </el-form-item>

        </el-form>


    </div>
</template>

<script>
    import {
        CreateWuyeCollectionPlan,
        GetProjectsWithoutAll,
        GetWuyeClientsValidCode,
        GetWuyeClientUnpaidBills,
        GetWuyeEmployeeList
    } from "../../api";
    import XEUtils from "xe-utils";
    import formatMoney from "../../utils/formatMoney";

    export default {
        name: "WuyeCollectionCreate",
        data() {
            return {
                unionid: null,
                loading: false,
                form: {
                    projName: '',
                    clientId: '',
                    amount: 0,
                    coordinator: [],
                    recipient: [],
                    remark: ''
                },
                rules: {
                    projName: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    clientId: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    coordinator: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    recipient: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ],
                    remark: [
                        {required: true, message: '不能为空', trigger: ['blur', 'change']}
                    ]
                },
                projects: [],
                clients: [],
                bills: [],
                selectIds: [],
                employeeList: []
            }
        },
        computed: {
            collectionedBillTable: function () {
                return {
                    height: '300',
                    showOverflow: false,
                    size: 'small',
                    rowConfig: {
                        isHover: true
                    },
                    checkboxConfig: {
                        checkMethod: this.checkMethod,
                        highlight: true
                    },
                    data: this.bills,
                    columns: [
                        {type: 'checkbox', field: 'check', title: '', width: 40},
                        {resizable: true, width: 80, title: '月份', field: 'billMonth'},
                        {resizable: true, title: '账单周期', field: 'chargeContent'},
                        {resizable: true, title: '房源', field: 'houseName'},
                        {resizable: true, title: '收费项目', field: 'u8fee'},
                        {
                            resizable: true,
                            title: '应收款',
                            field: 'receivedAmount',
                            formatter: 'FormatMoney',
                            className: 'font-red'
                        },
                        {resizable: true, title: '应收日', field: 'dueDate', sortable: true},
                        {
                            resizable: true, title: '逾期', formatter: function ({row}) {
                                return XEUtils.getDateDiff(new Date(row.dueDate).getTime(), new Date().getTime()).dd == undefined ?
                                    '' : (XEUtils.getDateDiff(new Date(row.dueDate).getTime(), new Date().getTime()).time / 86400000).toFixed(0) + ' 天'
                            }
                        },
                        {resizable: true, title: '备注', field: 'remark'}
                    ]
                }
            }
        },
        methods: {
            FormartMoney(value) {
                return formatMoney(value)
            },
            ProjChange() {
                this.loading = true
                this.form.clientId = ''
                this.form.amount = 0
                this.clients = []
                this.bills = []
                this.selectIds = []
                GetWuyeClientsValidCode(this.form.projName).then((res) => {
                    this.clients = res
                    this.loading = false
                })
            },
            ClientChange() {
                this.loading = true
                this.form.amount = 0
                this.bills = []
                this.selectIds = []
                GetWuyeClientUnpaidBills(this.form.clientId).then((res) => {
                    this.bills = res.reverse()
                    this.loading = false
                })
            },
            CheckboxChange() {
                var a = this.$refs.xTable.getCheckboxRecords(true)
                this.form.amount = 0
                this.selectIds = []

                if (a.length > 0) {

                    var total = 0

                    for (var i = 0; i < a.length; i++) {
                        total = total + a[i].receivedAmount
                        this.selectIds.push(a[i].id)
                    }
                }

                this.form.amount = total
            },
            Submit() {
                this.$refs.wuyeCollectionForm.validate((e) => {
                    if (e) {
                        this.loading = true
                        CreateWuyeCollectionPlan(this.unionid, this.form, this.selectIds).then((res) => {
                            if (res) {
                                this.$message.success('创建成功！');
                                this.$emit('WuyeCollectionCreateClose')
                                this.loading = false
                            }else{
                                this.$message.error('创建失败！');
                            }
                            this.loading = false
                        })
                    } else {
                        this.$message.error('有未填项！');
                    }
                })
            }
        },
        async created() {
            this.unionid = sessionStorage.getItem('unionid')
            this.loading = true

            await GetProjectsWithoutAll().then((res) => {
                this.projects = res
            })

            await GetWuyeEmployeeList(this.unionid).then((res) => {
                this.employeeList = res
            })

            this.loading = false
        }
    }
</script>

<style scoped>

    .container {
        width: 94%;
        margin-left: 3%;
    }

    .collectionedBillTable {
        margin-bottom: 20px;
    }

    .collectionedBillTable >>> .vxe-body--row {
        cursor: pointer;
    }

    .collectionedBillTable >>> .font-red {
        color: #f35b5b;
    }

</style>
