import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

Vue.config.productionTip = false

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import dayjs from 'dayjs'

Vue.prototype.dayjs = dayjs;

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts

// 自定义全局的格式化处理函数
VXETable.formats.mixin({
    // 面积 + ㎡
    FormatArea({cellValue}) {
        return cellValue + ' ㎡'
    },
    // 楼层 + F
    FormatFloor({cellValue}) {
        return cellValue + 'F'
    },
    /*过户情况*/
    FormatTransfer({cellValue}) {
        if (cellValue) {
            return '✅'
        } else {
            return ''
        }
    },
    /*两位小数*/
    FormatNum({cellValue}) {
        return Number(cellValue).toFixed(2)
    },
    /*百分比*/
    FormatPercent({cellValue}) {
        return Number(cellValue * 100).toFixed(2) + "%"
    },
    /*金额*/
    FormatMoney({cellValue}) {
        cellValue = cellValue || 0;
        var places = 2;
        var symbol = "￥";
        var thousand = ",";
        var decimal = ".";
        var negative = cellValue < 0 ? "-" : "",
            i = parseInt(cellValue = Math.abs(+cellValue || 0).toFixed(places), 10) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
        return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(cellValue - i).toFixed(places).slice(2) : "");
    },
    /*推送消息内容*/
    FormatWxMessage({cellValue}) {

        var arr = String(cellValue).split(",")

        return "推送月份：" + arr[0] + "，推送人数：" + arr[1]

    }

})
Vue.use(VXETable)

import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import {Login} from "./api";

VXETable.use(VXETablePluginExportXLSX)

/*路由拦截器*/
router.beforeEach((to, from, next) => {

    if (sessionStorage.getItem('unionid') || to.name === 'register') {
        if (to.name === 'register') {
            if (sessionStorage.getItem('newUnion')) {
                if (sessionStorage.getItem('newUnion') == 'null') {
                    next({
                        path: '/'
                    })
                } else {
                    next()
                }
            } else {
                next({
                    path: '/register'
                })
            }
        } else {
            next();
        }

    } else {
        if (to.name === 'login') {
            next()
        }
        if (to.name === 'home') {
            if (to.query.code) {
                Login(to.query.code).then((res) => {

                    var arr = res.split(',')
                    console.log(arr)

                    if (arr.length > 1) {
                        sessionStorage.setItem('newUnion', arr[1])

                        next({
                            path: '/register'
                        })
                    } else {
                        sessionStorage.setItem('unionid', res)
                        next()
                    }
                })
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
})

//sessionStorage.setItem('unionid', 'oGbrm53Wgo1FB81_aCy7dBYXufLg')
//sessionStorage.setItem('unionid', 'oGbrm579jODvncuCgj_xo6xX0HqY')

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
